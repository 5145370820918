<template>
  <div class="liveWrap">
    <main :class="bgColor ? 'mainBgWhite' : 'mainBgBlack'" style="padding:0">
      <div class="videoWrap">
        <div class="videoBox">
          <div class="topWrap">
           {{ kejian_title }}
          </div>
          <video :src="srcs" controls="controls" id="offcnplayer" />
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Header from "./components/header.vue";
import { previewvideo,toKey } from "./assets/api.js";
import {getCookie} from "@/assets/js/cookie.js";

export default {
  components: { Header},
  data() {
    return {
      bgColor: true,
      srcs:{},
      kejian_title:''
    };
  },
  mounted() {
    const params={
      sid:getCookie('sid'),
      sket:getCookie('sket')
    }
    toKey(params).then((res) => {
      if (res.code == 0) {
        this.getinfos();
      }
    });

  },
  methods: {
    getinfos() {
      let params = {
        video_id: this.$route.query.id,
      };
      previewvideo(params).then((res) => {
        if (res.code == 0) {
          this.kejian_title = res.data.video.title;
          // currentTime
          var video = document.getElementById("offcnplayer");
          var hls = new Hls();
          hls.loadSource(
                  res.data.video.host + res.data.video.m3u8url,
                  encodeURIComponent(res.data.video.m3u8)
          );
          // hls.loadSource(
          //         "https://kejian3.offcn.com/" + res.data.video.m3u8url,
          //         encodeURIComponent(res.data.video.m3u8)
          // );
          hls.attachMedia(video);
        }
      });
    },
  },

};
</script>

<style scoped lang="scss">
  @import "./assets/video.scss";
  #offcnplayer{ position: absolute}
  .colorBlue {
    color: #507fff;
  }
  .colorGreen {
    color: #42c9ab;
  }
  .colorRed {
    color: #fe4d67;
  }
  .liveWrap main .videoWrap .videoBox{ padding-bottom: 0 !important;}
</style>
